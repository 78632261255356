import React, { useState } from 'react'
import styled from 'styled-components';

import { PageQueryData } from './types'

import {
	Space,
	Scroller,
	Room,
	RoomSide,
	StoryItem as StoryItemm,
	Wallpaper,
	RoomSideEnum,
	useRoomMovement,
	RoomHoverText,
} from '../RoomExperience'

import { BaseWallpaper } from '../RoomExperience/components/Wallpaper'

import brand_batman from '../../images/3haedBatman.png'
import brand_underground from '../../images/InnovaUnderground.png'
import brand_green from '../../images/InnovationGraenaherbergid.png'
import brand_space from '../../images/Framleidslageimherb.png';
import brand_windows from '../../images/onnurhaedogITWindows.png'
import brand_tron from '../../images/fyrstahaedTron.png'

import { TransitionStatus } from 'react-transition-group/Transition';

export const wallData = {
	// I need sleep.
	underground: {
		src: "",
		title: "Underground",
		subTitle: "Together we will shapre what comes next"
	},
	batman: {
		src: "",
		title: "Batman",
		subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
	},
	green: {
		src: "",
		title: "Green room",
		subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
	},
	space: {
		src: "",
		title: "Space",
		subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
	},
	windows: {
		src: "",
		title: "Windows",
		subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
	},
	tron: {
		src: "",
		title: "Tron",
		subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
	}
}

interface SyningabasarProps {
	data: PageQueryData,
	showInfo?: boolean,
	transitionStatus: TransitionStatus,
	enableTilt?: boolean,
	greetingsImageSRC?:string
}
export default ({
	data,
	showInfo,
	transitionStatus,
	enableTilt,
	greetingsImageSRC
}: SyningabasarProps) => {

	const {
		scrollerRef,
	} = useRoomMovement({
		enableTilt,
		enableTopView: false,
		transitionStatus
	})

	const { roomWall, roomFloor } = data

	const [activeBas, setActiveBas] = useState({ ...wallData.underground })

	const handleHover = (wallItem: any) => {
		//setActiveBas({ ...wallItem })
	}

	return (
		<Space>
			<Scroller ref={scrollerRef}>
				<Room>
					{/* WALL_LEFT */}
					<RoomSide className={RoomSideEnum.Left}>
						<SolidWall color="linear-gradient(180deg, rgba(0,0,255,1) 0%, rgba(73,41,255,1) 94%)"></SolidWall>
					</RoomSide>


					{/* WALL_RIGHT */}
					<RoomSide className={RoomSideEnum.Right}>

						<SolidWall color="linear-gradient(180deg, rgba(0,0,255,1) 0%, rgba(73,41,255,1) 94%)"></SolidWall>

						<StoryItem
							xPos="18.5%"
							yPos="25%"
							heightPercentage="550px"
							widthPercentage="800px"
							handleHover={() => handleHover(wallData.underground)}
							to="/innova" direction="left"
							className="tiltOnHover"
						>
							<BrandLogoImage src={brand_underground} />
						</StoryItem>

						<StoryItem
							xPos="42.5%"
							yPos="25%"
							heightPercentage="550px"
							widthPercentage="800px"
							handleHover={() => handleHover(wallData.green)}
							to="/innovationogcrossindustryservice" direction="left"
							className="tiltOnHover"
						>

							<BrandLogoImage src={brand_green} />
						</StoryItem>

						<StoryItem
							xPos="64.5%"
							yPos="25%"
							heightPercentage="550px"
							widthPercentage="800px"
							handleHover={() => handleHover(wallData.space)}
							to="/framleidslaogframleidslustyring" direction="left"
							className="tiltOnHover"
						>

							<BrandLogoImage src={brand_space} />
						</StoryItem>

					</RoomSide>

					<RoomSide className={RoomSideEnum.Bottom}>
						<SolidWall color="#e3e3e3"></SolidWall>
					</RoomSide>

					<RoomSide className={RoomSideEnum.Top}>
						<SolidWall color="#e3e3e3"></SolidWall>
					</RoomSide>


					<RoomSide className={RoomSideEnum.Back}>
						<SolidWall color="#4929FF"></SolidWall>

						<StoryItem
							xPos="2%"
							heightPercentage="550px"
							widthPercentage="550px"
							handleHover={() => handleHover(wallData.tron)}
							to="/fyrstahaed-bistro-facilities" direction="left"
						>
							<BrandLogoImage src={brand_tron} />
						</StoryItem>

						<StoryItem
							xPos="35%"
							heightPercentage="550px"
							widthPercentage="550px"
							handleHover={() => handleHover(wallData.windows)}
							to="/onnurhaedogit" direction="left"
						>
							<BrandLogoImage src={brand_windows} />
						</StoryItem>

						<StoryItem
							xPos="68%"

							heightPercentage="550px"
							widthPercentage="550px"
							handleHover={() => handleHover(wallData.batman)}
							to="/thridjahaedogcrossindustry" direction="left"
						>
							<BrandLogoImage src={brand_batman} />
						</StoryItem>

					</RoomSide>

					<DoorManImg src={greetingsImageSRC} />
				</Room>

				{/* <RoomHoverText title={activeBas.title} /> */}
			</Scroller>
		</Space>
	)
}

const DoorManImg = styled.img`
	pointer-events: none;
	transform-origin: 50% 50%;
	justify-content: flex-start;
	transform: translate3d(-40px,35px,-750px) rotate3d(0,1,0,0deg);
	width: 500px;
	height: auto;
	position: absolute;
	left: 0;
	bottom: 0;
	backface-visibility: hidden;
	height: 80vh;
	/* pointer-events: none; */
`

interface SolidFloorProps {
	color: string
}
const SolidWall = styled.div<SolidFloorProps>`
  background: ${({ color }) => color};
  height:100%;
  width:100%;
`

const WallpaperTop = styled(Wallpaper)`
	background-repeat: no-repeat;
  background-size:cover;
`

const BrandLogoImage = styled.img`
	width: 100%;
	height: 100%;
`

const StoryTextWrapper = styled.div`
  position:absolute;
  width:100vw;
  bottom:150px;
  transform: translate3d(0px, 0px, -500px);
`

const StoryTextDescription = styled.p`
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
`

const StoryTextName = styled.h2`
  font-size:48px;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  color:#fff;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
`

const StoryItem = styled(StoryItemm)`
	display: flex;
	justify-content: center;
	align-items: center;
	transition:transform 0.3s ease-in;

	&.tiltOnHover:hover{
		transform: rotate3d(0, 1, 0, 25deg) translate3d(0,0,22vh) !important;
		transition:transform 0.3s ease-in;
	}
`

const Wall = styled.img`
	width: auto;
	height: 100%;
	transform-origin: center center;
	transform: translate3d(0px, 0px, 0px);
	
	/* &:nth-child(odd) {
		transform: translate3d(0px, 0px, 0px) rotate3d(0, 1, 0, 180deg) rotate3d(1, 0, 0, 0);
	} */
`

const WallpaperBack = styled(Wallpaper)`
	background-size: auto 100%;
	background-repeat: repeat-x;
	background-position: left;
	background-position: center;
`
const WallpaperFloor = styled(Wallpaper)`
	background-repeat: no-repeat;
	filter: brightness(70%);
	background-size: 100% 100%;
`

const TextContainer = styled.div`
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transform: translate3d(0px, 0px, 3px);
`

const Text = styled.h1`
	color: #1D1D1D;
	font-size: 360px;
	font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
	text-shadow: -1px 2px 0px rgba(255, 255, 255, 0.5);
`