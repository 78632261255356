import React, { useState, useEffect } from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout, { MyLocationEnum } from '../components/Layout'
import Syningabasar, { PageQueryData } from '../components/Syningabasar'
import { TransitionStatus } from 'react-transition-group/Transition'
import SEO, { MetaOG } from "../components/Seo"
import MobileSyningabasar from '../components/Syningabasar/MobileSyningabasar'
import greetingsImageSRC from '../images/velkomin-i-fordrykk-kall.png'

interface SyningabasarProps {
  data: PageQueryData,
  location: PageProps,
  transitionStatus: TransitionStatus,
}

export default ({ data, location, transitionStatus }: SyningabasarProps) => {
  const [enableTilt, setEnableTilt] = useState(true)

  const seo_og: MetaOG = {
    title: "Sýningarými",
    description: "Sýningarými fyrir stuðningsaðila",
  }

  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    const resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setShowMobile(resp)
  }, [])

  if (!showMobile) {
    return (
      <Layout
        myLocation={MyLocationEnum.Fordrykkur}
        location={location}
        transitionStatus={transitionStatus}
      >
        <SEO
          title="Fordrykkur"
          description="Fordrykkur"
          og={seo_og}
        />

        <Syningabasar
          enableTilt={enableTilt}
          data={data}
          transitionStatus={transitionStatus}
          greetingsImageSRC={greetingsImageSRC}
        />
      </Layout>
    )
  } else {
    return (
      <Layout
        myLocation={MyLocationEnum.Fordrykkur}
        location={location}
        transitionStatus={transitionStatus}
      >
        <SEO
          title="Fordrykkur"
          description="Fordrykkur"
          og={seo_og}
        />

        <MobileSyningabasar />
      </Layout>
    )
  }
}

// roomWall.png GatsbyImageSharpFluid_withWebp roomFloor.jpg squoosh_roomFloor
export const query = graphql`
  query {
    roomWall: file(relativePath: { eq: "squoosh_roomWall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomFloor: file(relativePath: { eq: "squoosh_roomFloor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomTop: file(relativePath: { eq: "Loft-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  } 
`