import React from "react"
import styled from "styled-components"

import brand_batman from '../../images/batman-logo.png'
import brand_underground from '../../images/underground-skilti.png'
import brand_green from '../../images/tron-seamless.png'
import brand_space from '../../images/ufo.png';
import brand_windows from '../../images/Windows-bakgrunnur.jpg'
import brand_tron from '../../images/fyrstahaedTron.png'


import { wallData } from "./Syningabasar"

const MobileSyningabasar = () => {
  return (
    <Container>
      <PageTitle>Sýningarými</PageTitle>
      <CompanyItem href="/fyrstahaed-bistro-facilities">
        <img src={brand_tron} alt="Space logo" />
        <div className="name">{wallData.tron.title}</div>
      </CompanyItem>
      <CompanyItem href="/onnurhaedogit">
        <img src={brand_windows} alt="windows image" />
        <div className="name">{wallData.windows.title}</div>
      </CompanyItem>
      <CompanyItem href="/thridjahaedogcrossindustry">
        <img src={brand_batman} alt="batman logo" />
        <div className="name">{wallData.batman.title}</div>
      </CompanyItem>
      <CompanyItem href="/innova">
        <img src={brand_underground} alt="underground logo" />
        <div className="name">{wallData.underground.title}</div>
      </CompanyItem>
      <CompanyItem href="/innovationogcrossindustryservice">
        <img src={brand_green} alt="green image" />
        <div className="name">{wallData.green.title}</div>
      </CompanyItem>
      <CompanyItem href="/framleidslaogframleidslustyring">
        <img src={brand_space} alt="space image" />
        <div className="name">{wallData.space.title}</div>
      </CompanyItem>
    </Container>
  )
}

const PageTitle = styled.h1`
  font-size: 32px;
  color: white;
  width: 100%;
  text-align: Center;
  padding-top: 100px;
  padding-bottom: 30px;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  margin-bottom: 30px;
`

const Container = styled.div`
  background: linear-gradient(189.62deg, #4f0077 0%, #ad0081 100%);
  padding-bottom: 30px;
`

const CompanyItem = styled.a`
  height: 220px;
  width: 90%;
  margin: 0 auto;
  background-color: #e6e6e6;
  border: 1px solid #303030;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  text-decoration: none;
  position: relative;

  .name {
    font-size: 24px;
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    color: #303030;
    width: 100%;
    padding: 10px 0 20px 15px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .logo {
    width: 70%;
    margin: 10px 10px 0 0;
    height: 50%;
    &.sixth {
      background-size: contain;
      background-repeat: no-repeat;
      
    }
  }

  img {
    width: 70%;
    margin: 10px 10px 0 0;
    max-height: 150px;
    &.seventh {
      width: 50%;
    }
  }
`

export default MobileSyningabasar
